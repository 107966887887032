<template>
	<v-container>
		<v-row
			align="center"
			justify="center">
			<h1>{{ courseSection.name }}</h1>
		</v-row>

		<v-row>
			<v-col
				cols="12"
				sm="6"
				md="4"
				v-for="(courseSection) in courseSections"
				:key="`section-${courseSection.id}`">
				<CourseSectionCard
					:course-section="courseSection">
				</CourseSectionCard>
			</v-col>

			<v-col
				cols="12"
				sm="6"
				md="4"
				v-for="lesson in lessons"
				:key="`lesson-${lesson.id}`">
				<LessonCard
					:lesson="lesson">
				</LessonCard>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'

import CourseSection from '../models/CourseSection'

import CourseSectionCard from '../components/Courses/CourseSectionCard'
import LessonCard from '../components/Courses/LessonCard'

export default {
	components: {
		CourseSectionCard,
		LessonCard,
	},
	props: {
		courseSectionId: {
			type: Number,
			required: true,
		},
	},
	computed: {
		courseSection() {
			return CourseSection.find(this.courseSectionId) || {}
		},
		courseSections() {
			return this.courseSection?.children || []
		},
		lessons() {
			return this.courseSection?.lessons || []
		},
	},
	methods: {
		...mapActions('notification', [
			'notify',
		]),

		async loadCourseSection(courseSectionId) {
			try {
				await CourseSection.fetchGet(courseSectionId)
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити дані секції',
					color: 'error',
				}, { root: true })
				throw error
			}
		},
	},
	watch: {
		courseSectionId: {
			immediate: true,
			handler() {
				this.loadCourseSection(this.courseSectionId)
			},
		},
	},
}
</script>
